var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.isEditing?'Editar':'Agregar')+" Dispositivo Admin")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre*","required":""},model:{value:(_vm.deviceNew.name),callback:function ($$v) {_vm.$set(_vm.deviceNew, "name", $$v)},expression:"deviceNew.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Imei*","hint":"Identificador del Dispositivo","required":""},model:{value:(_vm.deviceNew.uniqueid),callback:function ($$v) {_vm.$set(_vm.deviceNew, "uniqueid", $$v)},expression:"deviceNew.uniqueid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Telefono","required":""},model:{value:(_vm.deviceNew.phone),callback:function ($$v) {_vm.$set(_vm.deviceNew, "phone", $$v)},expression:"deviceNew.phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.iconList,"item-value":"image","label":"Icon"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"height":"30px"}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"height":"30px"}})]}}]),model:{value:(_vm.deviceNew.icon),callback:function ($$v) {_vm.$set(_vm.deviceNew, "icon", $$v)},expression:"deviceNew.icon"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"name","item-value":"id","label":"Types*","required":""},model:{value:(_vm.deviceNew.devicetypeid),callback:function ($$v) {_vm.$set(_vm.deviceNew, "devicetypeid", $$v)},expression:"deviceNew.devicetypeid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.userList,"item-text":"name","item-value":"id","label":"User*","required":""},model:{value:(_vm.deviceNew.userid),callback:function ($$v) {_vm.$set(_vm.deviceNew, "userid", $$v)},expression:"deviceNew.userid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Activo"},model:{value:(_vm.deviceNew.active),callback:function ($$v) {_vm.$set(_vm.deviceNew, "active", $$v)},expression:"deviceNew.active"}})],1)],1)],1),_c('small',[_vm._v("* Indicador de Requeridos")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveDev}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }