<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{isEditing?'Editar':'Agregar'}} Dispositivo Admin</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Nombre*"
                  required
                  v-model="deviceNew.name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Imei*"
                  hint="Identificador del Dispositivo"
                  v-model="deviceNew.uniqueid"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Telefono"
                  v-model="deviceNew.phone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-select :items="iconList"  item-value="image" label="Icon" v-model="deviceNew.icon">
                        <template v-slot:selection="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                        <template v-slot:item="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                    </v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="types" 
                    item-text="name"
                    item-value="id"
                    label="Types*" 
                    v-model="deviceNew.devicetypeid"
                    required
                    ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="userList" 
                    item-text="name"
                    item-value="id"
                    label="User*" 
                    v-model="deviceNew.userid"
                    required
                    ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-switch label="Activo" v-model="deviceNew.active" />
              </v-col>
            </v-row>
          </v-container>
          <small>* Indicador de Requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveDev"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
      types: {
          type:Array
          ,default: () =>  []
        }
        ,iconList:{
          type:Array
          ,default:()=>[]
        }
        ,userList:{
          type:Array
          ,default:()=>[]
        }
        ,editItem:{
          type:Object
          ,default:()=>{}
        }
        ,openDialog:{
          type:Boolean
          ,default:false
        }
    },
    data: ()=>({
        dialog:false,
        deviceNew:{
          name:null,
          uniqueid:null,
          icon:null,
          devicetypeid:0,
          phone:null,
        }
    }),
    mounted(){
      //console.log('monted');
        //this.deviceNew.Id = this.editItem.Id;
        if(!this.isEditing)
            this.deviceNew = Object.assign({},{
                    name:null,
                    uniqueid:null,
                    icon:null,
                    devicetypeid:0,
                    phone:null,
                  });
        else
            this.deviceNew = Object.assign({},this.editItem);
    },
    computed:{
        isEditing(){
            return this.deviceNew.id !== undefined;
        }
    },
    watch:{
      openDialog(newVal){
        this.dialog = newVal;
      }
      , editItem(newVal){

                this.deviceNew = Object.assign({},newVal);
                //this.pay.deviceId = this.deviceId;
                //console.log(this.deviceNew);
        }
    },
    methods:{
      ...mapActions(['addDevice','showSnackbar','updateDevice']),
      saveDev(){

        if(!this.deviceNew.name || !this.deviceNew.uniqueid  || !this.deviceNew.phone  || this.deviceNew.devicetypeid < 1 || this.deviceNew.userid < 1){
          this.showSnackbar({
                showing:true,
                message: `Favor de verificar los datos`,
                color:'info'
              });
          return;
        }


        if(this.isEditing){
          //console.log('edit');
          this.updateDevice(this.deviceNew).then((res)=>{
            //console.log(res);
            if(res.status == 200)
              this.showSnackbar({
                showing:true,
                message: `Updated device ${res.data.data.name}!`,
                color:'success'
              });

            this.$emit('saved',this.deviceNew);
            this.close();
              
            //this.toggleCard(card);
          }) .catch((err)=>{
                console.log(err);
                if(err.data.errors)
                this.showSnackbar({
                  showing:true,
                  message: err.data.errors[0],
                  color:'error'
                });
            });

        }
        else{
          this.addDevice(this.deviceNew).then((res)=>{
            //console.log(res);
            if(res.status == 200)
              this.showSnackbar({
                showing:true,
                message: `Created device ${res.data.data.name}!`,
                color:'success'
              });
            
            this.$emit('saved',this.deviceNew);
            this.close();

          }) .catch((err)=>{
                console.log(err);
                if(err.data.errors)
                this.showSnackbar({
                  showing:true,
                  message: err.data.errors[0],
                  color:'error'
                });
            });
        }

        
      },
      close(){
        this.deviceNew = Object.assign({},{
                    name:null,
                    uniqueid:null,
                    icon:null,
                    devicetypeid:0,
                    phone:null,
                });
        this.dialog =  false;
        this.$emit('close');
      }
    }
}
</script>

<style>

</style>